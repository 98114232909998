import * as Yup from "yup";

export const customerSchema = Yup.object({
 customerName: Yup.string().min(2).required("Please enter your name"),
 invoiceDate: Yup.string().required("Please enter date"),
//  gstNo: Yup.string().required("Please enter gst no."),
//  panNo: Yup.string().required("Please enter pan no."),
//  inputEmail: Yup.string().required("Please enter email address."),
 typeOfEnquiry: Yup.string().required("Please select the enquiry type."),

// mobileNo: Yup.string()
//   .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
//   .required('Please enter your mobile number'),

mobileNo: Yup.string()
  .matches(/^(0\d{10}|[1-9]\d{9})$/, 'Mobile number must be 10 digits')
  .required('Please enter your mobile number'),

  
  
  exampleFormControlTextarea1: Yup.string().min(2).max(100).required("Please enter address "),

});
